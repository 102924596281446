import { useEffect, useState } from "react";
import delImg from "../images/delete.svg";
import Sidemenu from "./menu/sidemenu";
import moment from "moment";
import { showErrorMsg, showSuccessMsg } from "../utils/notification";

import { deleteSingleInquiry, getInquiry } from "../modules/listing/actions";

const BuyerInquiryList = () => {
  const [inquiryList, setInquiryList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllInquiry();
  }, []);

  const getAllInquiry = () => {
    setIsLoading(true);
    getInquiry("investments").then(
      (res) => {
        setIsLoading(false);
        if (res?.statuscode === 200) {
          setInquiryList(res?.data);
        } else {
          showErrorMsg(res?.message);
        }
      },
      (err) => {
        setIsLoading(false);
      }
    );
  };

  const deleteInquiry = (id) => {
    deleteSingleInquiry(id, "investments").then(
      (res) => {
        showSuccessMsg("Deleted successfully!");
        getAllInquiry();
      },
      (err) => {
        showSuccessMsg("Something went wrong!");
      }
    );
  };
  return (
    <>
      <Sidemenu />
      <div className="relative md:ml-64 bg-blueGray-100 mt-30 px-4 ">
        <nav className="w-full bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center">
          <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap">
            <h3>Buyer Inquiry</h3>
          </div>
        </nav>

        <div className="relative bg-lightBlue-600 pb-32 dash-table mt-10">
          <div className="block w-full overflow-x-auto">
            {isLoading && <h3 className="text-center">Loading Data...</h3>}
            {!isLoading && (
              <table className="items-center w-full bg-transparent border-collapse">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Description</th>
                    <th>Purchase Type</th>
                    <th>Created At</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {inquiryList.map((data, index) => (
                    <tr key={index}>
                      <td>{data.name}</td>
                      <td>{data.email}</td>
                      <td>{data.phone}</td>
                      <td className="w-25">{data.description}</td>
                      <td>{data.type === true ? "BlitsCoin" : "Property"} </td>
                      <td>{moment(data.createdAt).format("DD MMM YYYY")}</td>
                      <td>
                        <img
                          className="cursorPointer"
                          onClick={(e) => deleteInquiry(data.id)}
                          src={delImg}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BuyerInquiryList;
