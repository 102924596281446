import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import delImg from "../images/delete.svg";
import editImg from "../images/edit.svg";
import Sidemenu from "./menu/sidemenu";
import moment from "moment";
import { showErrorMsg, showSuccessMsg } from "../utils/notification";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import {
  getProperties,
  updateApproved,
  deletePropertyEp,
} from "../modules/listing/actions";

import { loadStripe } from "@stripe/stripe-js";

import { Navigate } from "react-router-dom";
import Web3 from "web3";
import factoryABI from "../utils/factoryABI";
import Spinner from "../components/Loader";

const API_STAGE = process.env.REACT_APP_BASE_STAGE_URL;
const Listing = () => {
  const [propertyList, setProperyList] = useState([]);

  const [isFetchingProperty, setIsFetchingProperty] = useState(false);

  const [show, setShow] = useState(false);

  const [modEvt, setModEvt] = useState(null);

  const [showhold, setShowhold] = useState(false);

  const [modEvthold, setModEvthold] = useState(null);

  const [propData, setPropData] = useState(null);

  const handleClose = () => setShow(false);

  const handleClosehold = () => setShowhold(false);

  const [web3Enabled, setWeb3Enabled] = useState(false);

  const [accounts, setAccounts] = useState([]);

  const [nameContract, setNameContract] = useState(null);

  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    getAllProperty();
    ethEnabled();
  }, []);

  const ethEnabled = async () => {
    if (typeof window.ethereum !== "undefined") {
      // Instance web3 with the provided information from the MetaMask provider information
      web3 = new Web3(window.ethereum);
      try {
        // Request account access
        await window.ethereum.enable();

        metamaskDetails();
        return true;
      } catch (e) {
        // User denied access
        return false;
      }
    } else {
      alert("Please install MetaMask to use this service!");
      window.open(
        "https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn?hl=en",
        "_blank"
      );
    }

    return false;
  };
  // let NameContract;
  const metamaskDetails = async () => {
    setWeb3Enabled(true);
    var accs = await web3.eth.getAccounts();
    setAccounts(accs);

    const factoryAddress = "0xd5151881c97c53de667214560b3232b8f47665d6";

    const NameContract = new web3.eth.Contract(factoryABI, factoryAddress);
    setNameContract(NameContract);
  };

  const getAllProperty = () => {
    setIsFetchingProperty(true);
    getProperties().then(
      (res) => {
        let list = res.data.data;
        let sortData = list.sort((a, b) => b.id - a.id);
        setProperyList(sortData);
        setIsFetchingProperty(false);
      },
      (err) => {
        setIsFetchingProperty(false);
      }
    );
  };

  const statusChange = (event, data) => {
    setShow(true);
    setModEvt(event);
    setPropData(data);
  };

  let web3 = new Web3();

  const updateStatus = async () => {
    if (modEvt === "1") {
      setIsloading(true);
      var prop_name = propData.title;
      var prop_symbol = propData.public_url;
      var prop_shares = propData.shares;
      var share_price = propData.share_value;
      console.log(nameContract);
      try {
        var hash1, receipt1;
      await nameContract.methods
        .createDAO(prop_name, prop_symbol, prop_shares, share_price)
        .send({ from: accounts[0] })
        .on("transactionHash", function (hash) {
          console.log(hash);
          hash1 = hash;
        })
        .on("receipt", function (receipt) {
          receipt1 = receipt;
          console.log(receipt); 
          
        })
        .on("error", function (error, receipt) {
          setIsloading(false);
          showErrorMsg("Something went wrong");
        });

        let propAdd = receipt1.events[0].address;
          var urlencoded = new URLSearchParams();
          urlencoded.append("data[approved]", modEvt);
          urlencoded.append("propertyId", propData.id);
          urlencoded.append("data[wallet_address]", propAdd);
          updateApproved(urlencoded).then(
            (res) => {
              setPropData(null);
              setModEvt(null);
              setShow(false);
              setIsloading(false);
              // console.log(res);
              showSuccessMsg("Updated successfully!");
              window.location.reload(false);
            },
            (err) => {
              setPropData(null);
              setModEvt(null);
              setShow(false);
              setIsloading(false);
              showSuccessMsg("Something went wrong!");
            }
          );
      } catch (err) {
        console.log(err);
        switch(err.code) {
            case 4001:
              showErrorMsg('You have rejected the transaction from your end');
              break;
            case -32003:
              showErrorMsg('Your wallet has insufficient balance to process the transaction. Kindly update your wallet balance.');
              break;
            case -32603:
              showErrorMsg('Internal JSON-RPC error');
              break;
            default:
              showErrorMsg('Oops, we have encountered an error. (Error Code -  '+ err.code +')');
            break;
        }

    }
    } else {
      var urlencoded = new URLSearchParams();
      urlencoded.append("data[approved]", modEvt);
      urlencoded.append("propertyId", propData.id);
      updateApproved(urlencoded).then(
        (res) => {
          setPropData(null);
          setModEvt(null);
          setShow(false);
          // console.log(res);
          showSuccessMsg("Updated successfully!");
          window.location.reload(false);
        },
        (err) => {
          setPropData(null);
          setModEvt(null);
          setShow(false);
          showSuccessMsg("Something went wrong!");
          // console.log(err);
        }
      );
    }
  };
  const holdChange = (event, data) => {
    // console.log(event, data);
    setShowhold(true);
    setModEvthold(event === "1" ? 0 : 1);
    setPropData(data);
  };
  const holdStatus = () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("data[hold]", modEvthold);
    urlencoded.append("propertyId", propData.id);
    updateApproved(urlencoded).then(
      (res) => {
        setPropData(null);
        setModEvthold(null);
        setShowhold(false);
        // console.log(res);
        showSuccessMsg("Updated successfully!");
        window.location.reload(false);
      },
      (err) => {
        setPropData(null);
        setModEvthold(null);
        setShowhold(false);
        showSuccessMsg("Something went wrong!");
        // console.log(err);
      }
    );
  };
  const deleteProperty = (id) => {
    deletePropertyEp(id).then(
      (res) => {
        showSuccessMsg("Deleted successfully!");
        getAllProperty();
      },
      (err) => {
        showSuccessMsg("Something went wrong!");
      }
    );
  };
  return (
    <>
      <Sidemenu />
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton={!isLoading}>
          <Modal.Title>
            {modEvt === "0"
              ? "In Progress"
              : modEvt === "1"
              ? "Approve"
              : modEvt === "2"
              ? "Reject"
              : null}{" "}
            Confirmation
          </Modal.Title>
        </Modal.Header>
        {isLoading ? (
          <Modal.Body>
            Please wait while we are publishing your property. This might take a
            while...
          </Modal.Body>
        ) : (
          <Modal.Body>
            Do you really want to
            {modEvt === "0"
              ? " move In Progress"
              : modEvt === "1"
              ? " approve"
              : modEvt === "2"
              ? " reject"
              : null}{" "}
            this property?
            {/* Do you really want to buy {shareAmt} shares of {data.title}? */}
          </Modal.Body>
        )}
        <Modal.Footer>
          {isLoading ? (
            <Button variant="primary" disabled>
              Processing &nbsp; <Spinner size={true} />
            </Button>
          ) : (
            <>
              <Button variant="secondary" onClick={handleClose}>
                No
              </Button>
              <Button variant="primary" onClick={updateStatus}>
                Yes
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      {/*  this is for 2nd popup for hold button */}
      <Modal show={showhold} onHide={handleClosehold}>
        <Modal.Header closeButton>
          <Modal.Title>
            {modEvthold === "1"
              ? "hold"
              : modEvthold === "0"
              ? "release"
              : null}{" "}
            Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you really want to
          {modEvthold === "0"
            ? " hold"
            : modEvthold === "1"
            ? " release"
            : null}{" "}
          this property?
          {/* Do you really want to buy {shareAmt} shares of {data.title}? */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosehold}>
            No
          </Button>
          <Button variant="primary" onClick={holdStatus}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="relative md:ml-64 bg-blueGray-100 mt-30 px-4 ">
        <nav className="w-full bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center">
          <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap">
            <h3>All properties</h3>
            {/* <div className="flex-col md:flex-row list-none items-center hidden md:flex def-btn">
              Add new property
            </div> */}
          </div>
        </nav>

        <div className="relative bg-lightBlue-600 pb-32 dash-table mt-10">
          <div className="block w-full overflow-x-auto">
            {isFetchingProperty && (
              <h3 className="text-center">Loading Data...</h3>
            )}
            {!isFetchingProperty && (
              <table className="items-center w-full bg-transparent border-collapse">
                <thead>
                  <tr>
                    <th>Name and location</th>
                    <th>Launched On</th>
                    <th>Cost (in USD)</th>
                    <th>Dilution</th>
                    <th>Share Values</th>
                    <th>Total shares</th>
                    <th>Shares sold</th>
                    <th>Owner/Builder</th>
                    <th>Wallet Address</th>
                    <th>Status</th>
                    <th>Hold</th>
                    <th>Delete</th>
                    <th>Paid Status</th>
                    {/* <th>Edit</th> */}
                    {/* <th>Select</th> */}
                  </tr>
                </thead>
                <tbody>
                  {propertyList.map((data, index) => (
                    <tr key={index}>
                      <td>
                        <a
                          target="_blank"
                          href={`${API_STAGE}/detail-page/${data.public_url}`}
                        >
                          <div className="villa-img">
                            <img src={data.tile_image} alt="" />
                          </div>
                          <div className="villa-title">
                            <h5>{data.title}</h5>
                            <h6>
                              {data.city}, {data.country}
                            </h6>
                          </div>
                        </a>
                      </td>
                      <td>{moment(data.launched_at).format("DD MMM YYYY")}</td>
                      <td>${data.cost}</td>
                      <td>{data.dilution}</td>
                      <td>{data.share_value} </td>
                      <td>{data.shares} </td>
                      <td>{data.purchased}</td>
                      <td>{data.user_name}</td>
                      {data.wallet_address !== null ? (
                        <td>
                          {data.wallet_address.substring(0, 3) +
                            "..." +
                            data.wallet_address.substring(
                              data.wallet_address.length - 3
                            )}
                        </td>
                      ) : (
                        <td>NA</td>
                      )}
                      <td>
                        <span className="price-sp">
                          <select
                            name="approval"
                            id={index}
                            onChange={(e) => statusChange(e.target.value, data)}
                            className={`form-control d-inline-flex price-select ${
                              data.approved === 0
                                ? "border-warning text-warning"
                                : data.approved === 1
                                ? "border-success text-success"
                                : "border-danger text-danger"
                            }`}
                          >
                            <option
                              data-price="0"
                              value="0"
                              selected={data.approved === 0 ? "selected" : ""}
                            >
                              In Progress
                            </option>
                            <option
                              value="1"
                              selected={data.approved === 1 ? "selected" : ""}
                            >
                              Approved
                            </option>
                            <option
                              value="2"
                              selected={data.approved === 2 ? "selected" : ""}
                            >
                              Rejected
                            </option>
                          </select>
                        </span>
                      </td>
                      <td>
                        <span className="price-sp">
                          <select
                            name="hold"
                            id={index}
                            onChange={(e) => holdChange(e.target.value, data)}
                            className={`form-control d-inline-flex price-select ${
                              data.hold === 1
                                ? "border-danger text-danger"
                                : "border-success text-success"
                            }`}
                          >
                            <option
                              data-price="0"
                              value="0"
                              selected={data.hold === 1 ? "selected" : ""}
                            >
                              Hold
                            </option>
                            <option
                              value="1"
                              selected={data.hold === 0 ? "selected" : ""}
                            >
                              Release
                            </option>
                          </select>
                        </span>
                      </td>
                      <td>
                        <img
                          className="cursorPointer"
                          onClick={(e) => deleteProperty(data.id)}
                          src={delImg}
                        />
                      </td>
                      <td>{data.paid === 0 ? "Not Paid" : "Paid"} </td>
                      {/* <td>
                        <img src={editImg} />
                      </td> */}
                      {/* <td>
                        <input type="checkbox" />{" "}
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Listing;
