import { useEffect, useRef, useState } from "react";
import delImg from "../images/delete.svg";
import editImg from "../images/edit.svg";
import Sidemenu from "./menu/sidemenu";
import moment from "moment";
import { getInvestments } from "../modules/listing/actions";

const Coindetails = () => {
  const [coinList, setCoinList] = useState([]);
  const [isFetchingProperty, setIsFetchingProperty] = useState(false);

  useEffect(() => {
    getAllCoins();
  }, []);

  const getAllCoins = () => {
    setIsFetchingProperty(true);
    getInvestments().then(
      (res) => {
        let list = res.data.data;
        setCoinList(list);
        setIsFetchingProperty(false);
      },
      (err) => {
        setIsFetchingProperty(false);
      }
    );
  };

  return (
    <>
      <Sidemenu />
      <div className="relative md:ml-64 bg-blueGray-100 mt-30 px-4 ">
        <nav className="w-full bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center">
          <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap">
            <h3>All Investments</h3>
          </div>
        </nav>

        <div className="relative bg-lightBlue-600 pb-32 dash-table mt-10">
          <div className="block w-full overflow-x-auto">
            {isFetchingProperty && (
              <h3 className="text-center">Loading Data...</h3>
            )}
            {!isFetchingProperty && (
              <table className="items-center w-full bg-transparent border-collapse">
                <thead>
                  <tr>
                    <th>User Name</th>
                    <th>Property Name</th>
                    <th>No.Shares </th>
                    <th>Coins</th>
                    <th>Transaction Date</th>
                    {/* <th>Delete</th>
                    <th>Edit</th> */}
                    {/* <th>Select</th> */}
                  </tr>
                </thead>
                <tbody>
                  {coinList.map((data, index) => (
                    <tr key={index}>
                      <td>{data.user_name}</td>
                      <td>{data.title !== null ? data.title : "NA"}</td>
                      <td>{data.quantity}</td>

                      <td>{data.coins}</td>
                      <td>{moment(data.created_at).format("DD MMM YYYY")}</td>
                      {/* <td>
                        <img src={delImg} />
                      </td>
                      <td>
                        <img src={editImg} />
                      </td> */}
                      {/* <td>
                        <input type="checkbox" />{" "}
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
      )
    </>
  );
};

export default Coindetails;
