import { useState } from "react";
import S3FileUpload from "react-s3";
import Sidemenu from "../menu/sidemenu";
import { showErrorMsg, showSuccessMsg } from "../../utils/notification";
import * as Yup from "yup";
import { addBlog } from "../../modules/blog/actions";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";

const BlogAdd = () => {
  const navigate = useNavigate();

  window.Buffer = window.Buffer || require("buffer").Buffer;

  const [isImageRequired, setIsImageRequired] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");

  const config = {
    bucketName: process.env.REACT_APP_BUCKET_NAME,
    dirName: process.env.REACT_APP_BUCKET_DIR,
    region: process.env.REACT_APP_BUCKET_REGION,
    accessKeyId: process.env.REACT_APP_ACCESS_KEY,
    secretAccessKey: process.env.REACT_APP_SECRET_KEY,
  };

  // upload image function
  const uploadImage = async (e) => {
    // console.log(e);
    const file = e.target.files[0];
    if (
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/svg" ||
      file.type === "image/jpg" ||
      file.type === "image/webp"
    ) {
      S3FileUpload.uploadFile(file, config)
        .then((data) => {
          let item = data.location;
          setUploadedImageUrl(item);
          setIsImageRequired(false);
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      showErrorMsg("File Type not supported");
    }
  };

  const blogFormSubmit = (values, { setSubmitting, resetForm }) => {
    var params = {
      title: values.title,
      tags: values.tags,
      image: uploadedImageUrl,
      description: values.description,
    };
    if (!isImageRequired) {
      addBlog(params).then(
        (res) => {
          setSubmitting(false);
          if (res?.statuscode === 200) {
            showSuccessMsg(res?.message);
            resetForm({ values: "" });
            navigate("/blogs");
          } else {
            showErrorMsg(res?.message);
          }
        },
        (err) => {
          setSubmitting(false);
          showErrorMsg(err.message);
        }
      );
    } else {
      setIsImageRequired(true);
      setSubmitting(false);
    }
  };

  const blogSchema = Yup.object().shape({
    title: Yup.string().required("Please enter a title"),
    tags: Yup.string().required("Please enter a tags"),
    description: Yup.string().required("Please enter a description"),
  });

  const blogFormSchema = () => {
    return {
      title: "",
      tags: "",
      description: "",
    };
  };

  return (
    <>
      <Sidemenu />
      <div className="relative md:ml-64 bg-blueGray-100 mt-30 px-4 ">
        <nav className="w-full bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center">
          <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap">
            <h3>Add Blog</h3>
            <Link to={"/blogs"} className="btn btn-dark">
              Cancel
            </Link>
          </div>
        </nav>
        <div className="form-wrap mt-5">
          <Formik
            initialValues={blogFormSchema()}
            validationSchema={blogSchema}
            onSubmit={blogFormSubmit}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="row">
                  <div className="col-lg-8">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label htmlFor="" className="form-label">
                            Title
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            name="title"
                          />
                          <ErrorMessage
                            name="title"
                            component="div"
                            className="error-msg pl-2"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group mb-3">
                          <label htmlFor="" className="form-label">
                            Tags
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            name="tags"
                          />
                          <ErrorMessage
                            name="tags"
                            component="div"
                            className="error-msg pl-2"
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group mb-3">
                          <label htmlFor="" className="form-label">
                            Description
                          </label>
                          <Field
                            as="textarea"
                            className="form-control"
                            name="description"
                            rows="6"
                          />
                          <ErrorMessage
                            name="description"
                            component="div"
                            className="error-msg pl-2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group mb-3">
                      <label htmlFor="" className="form-label">
                        Image
                      </label>
                      <input
                        type="file"
                        className="form-control"
                        onChange={(e) => uploadImage(e)}
                      />
                      {isImageRequired && (
                        <div className="error-msg pl-2">
                          Please select any image
                        </div>
                      )}
                    </div>
                    {uploadedImageUrl !== "" && (
                      <div className="form-uploaded-image">
                        <img
                          src={uploadedImageUrl}
                          alt=""
                          className="rounded"
                          style={{ maxWidth: "200px", objectFit: "cover" }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-lg-12">
                    <button
                      type="submit"
                      className="btn btn-dark py-2 px-5"
                      disabled={isSubmitting || isImageRequired}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default BlogAdd;
