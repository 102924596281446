import "./App.css";
import Login from "./login/Login";
import Header from "./Header";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Listing from "./pages/Listing";
import Coindetails from "./pages/Coindetails";
import Userinvestments from "./pages/Userinvestments";
import Userdetails from "./pages/Userdetails";
import { ToastContainer } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import NotFoundPage from "./pages/NotFoundPage";
import BuyerInquiryList from "./pages/BuyerInquiry";
import SellerInquiryList from "./pages/SellerInquiry";
import AgentInquiryList from "./pages/AgentInquiry";
import BlogList from "./pages/Blog";
import BlogAdd from "./pages/Blog/add";

function App() {
  return (
    <>
      <BrowserRouter>
        <ToastContainer />
        <Header />
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="coindetails" element={<Coindetails />} />
          <Route path="userinvestments" element={<Userinvestments />} />
          <Route path="listing" element={<Listing />} />
          <Route path="userdetails" element={<Userdetails />} />
          <Route path="buyer-inquiry" element={<BuyerInquiryList />} />
          <Route path="seller-inquiry" element={<SellerInquiryList />} />
          <Route path="agent-inquiry" element={<AgentInquiryList />} />
          <Route path="blogs" element={<BlogList />} />
          <Route path="blogs/add" element={<BlogAdd />} />
          {/* 404 route */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
