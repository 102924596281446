import { getToken } from "../../utils/helper";
import axios from "axios";

const API_ENDPOINT = process.env.REACT_APP_BASE_URL;
/**
 *  Get all property
 */
export async function getProperties(url) {
  try {
    const token = getToken();
    const response = await axios({
      method: "get",
      url: `${API_ENDPOINT}/adminlisting` + "?" + url,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + token,
      },
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

export async function updateApproved(params) {
  try {
    const token = getToken();
    const response = await axios({
      method: "post",
      url: `${API_ENDPOINT}/editproperty`,
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + token,
      },
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}
export async function updateUser(params) {
  try {
    const token = getToken();
    const response = await axios({
      method: "post",
      url: `${API_ENDPOINT}/edituser`,
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + token,
      },
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}
export async function deletePropertyEp(params) {
  try {
    const token = getToken();
    const response = await axios({
      method: "delete",
      url: `${API_ENDPOINT}/delete/${params}`,
      data: params,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + token,
      },
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

export async function getTransactions() {
  try {
    const token = getToken();
    const response = await axios({
      method: "get",
      url: `${API_ENDPOINT}/coinTransactions`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + token,
      },
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

/**
 *  Get users
 */
export async function fetchUsers() {
  try {
    const token = getToken();
    const response = await axios({
      method: "get",
      url: `${API_ENDPOINT}/userlist`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + token,
      },
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

export async function getInvestments() {
  try {
    const token = getToken();
    const response = await axios({
      method: "get",
      url: `${API_ENDPOINT}/investmenttransactions`,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: "Bearer " + token,
      },
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

export async function getInquiry(type) {
  try {
    const response = await axios({
      method: "get",
      url: `${API_ENDPOINT}/${type}`,
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

export async function deleteSingleInquiry(id, type) {
  try {
    const response = await axios({
      method: "delete",
      url: `${API_ENDPOINT}/${type}/delete/${id}`,
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}
