import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { logout } from "./utils/helper";
import { showWarningMsg } from "./utils/notification";
import { getUserProfile } from "./modules/auth/actions";

const Header = () => {
  const [userName, setUsername] = useState(null);

  const [isLoading, setisLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getUserData();
  }, []);

  const getUserData = () => {
    setisLoading(true);
    getUserProfile().then(
      (res) => {
        var user =
          res.data[0].user_name !== null ? res.data[0].user_name : "NA";
        setUsername(user);
        setisLoading(false);
      },
      (err) => {
        returnAuthandRoute();
        setUsername(null);
        setisLoading(false);
      }
    );
  };

  const logoutUser = () => {
    logout();
    setUsername(null);
    navigate("/login");
  };

  const returnAuthandRoute = () => {
    if (userName !== null) {
      navigate("/login");
    } else {
      showWarningMsg("Invalid Login");
      setTimeout(() => {
        navigate("/login");
      }, 10);
    }
  };

  return (
    <>
      <nav className="top-0 fixed z-40 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-blue">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <a
              className="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap cursor-pointer logo text-decoration-none"
              onClick={() => navigate("/")}
            >
              Blits Estates Admin
            </a>
          </div>

          <div className="lg:flex flex-grow items-center">
            {!isLoading ? (
              <>
                {" "}
                <a
                  className="flex flex-col lg:flex-row list-none lg:ml-auto text-white cursor-pointer text-decoration-none"
                  onClick={logoutUser}
                >
                  Logout
                </a>
              </>
            ) : null}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;
