import { useEffect, useState } from "react";
import delImg from "../../images/delete.svg";
import Sidemenu from "../menu/sidemenu";
import moment from "moment";
import { showErrorMsg, showSuccessMsg } from "../../utils/notification";

import { deleteSingleBlog, getBlogs } from "../../modules/blog/actions";
import { Link } from "react-router-dom";

const BlogList = () => {
  const [blogsList, setBLogsList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getAllBlogs();
  }, []);

  const getAllBlogs = () => {
    setIsLoading(true);
    getBlogs().then(
      (res) => {
        setIsLoading(false);
        if (res?.statuscode === 200) {
          setBLogsList(res?.data);
        } else {
          showErrorMsg(res?.message);
        }
      },
      (err) => {
        setIsLoading(false);
      }
    );
  };

  const deleteBlog = (id) => {
    deleteSingleBlog(id).then(
      (res) => {
        showSuccessMsg("Deleted successfully!");
        getAllBlogs();
      },
      (err) => {
        showSuccessMsg("Something went wrong!");
      }
    );
  };

  return (
    <>
      <Sidemenu />
      <div className="relative md:ml-64 bg-blueGray-100 mt-30 px-4 ">
        <nav className="w-full bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center">
          <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap">
            <h3>Blogs</h3>
            <Link to={"/blogs/add"} className="btn btn-dark">
              Add Blog
            </Link>
          </div>
        </nav>

        <div className="relative bg-lightBlue-600 pb-32 dash-table mt-10">
          <div className="block w-full overflow-x-auto">
            {isLoading && <h3 className="text-center">Loading Data...</h3>}
            {!isLoading && (
              <table className="items-center w-full bg-transparent border-collapse">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>Title</th>
                    <th>Tags</th>
                    <th>Description</th>
                    <th>Created At</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {blogsList.map((data, index) => (
                    <tr key={index}>
                      <td>
                        <img
                          src={data?.image}
                          alt=""
                          className="img-fluid"
                          style={{ maxWidth: "200px" }}
                        />
                      </td>
                      <td>{data.title}</td>
                      <td>{data.tags}</td>
                      <td>{data.description}</td>
                      <td>{moment(data.createdAt).format("DD MMM YYYY")}</td>
                      <td>
                        <img
                          className="cursorPointer"
                          onClick={(e) => deleteBlog(data.id)}
                          src={delImg}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogList;
