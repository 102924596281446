import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getLocalStorage, setLocalStorage } from "../utils/helper";
import { showErrorMsg, showSuccessMsg } from "../utils/notification";

const API_ENDPOINT = process.env.REACT_APP_BASE_URL;

/* async function loginUser(credentials) {
    return fetch('http://localhost:5020//filmrare/getUser',{

        method: 'POST',
        headers: {
            'Content-Type':'application/json'
        },
        body:JSON.stringify(credentials)
    }).then(data => data.json())
} */

const Login = () => {
  localStorage.setItem("authenticated", "pottan");
  const navigate = useNavigate();
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [authenticated, setauthenticated] = useState(
    localStorage.getItem(localStorage.getItem("authenticated") || false)
  );
  const [username, setUsername] = useState("");
  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    var params = {
      email: email,
      password: password,
    };
    loginUser(params).then(
      (res) => {
        setLocalStorage(res);
        showSuccessMsg("Logged in");
        //setSubmitting(false);
        navigate("/listing");
        localStorage.setItem("authenticated", true);
        setUsername("Admin");
        setError(false);
        window.location.reload(true);
      },
      (err) => {
        //setSubmitting(false);
        setUsername("");
        showErrorMsg(err.message);
        setError(true);
      }
    );

    async function loginUser(params) {
      try {
        const response = await axios({
          method: "post",
          url: `${API_ENDPOINT}/authenticateadmin `,
          data: params,
        });
        if (response.data) {
          return response.data;
        }
        throw response;
      } catch (error) {
        throw error.response.data;
      }
    }
  };
  useEffect(() => {
    checkUserData();
  });

  const checkUserData = () => {
    const storage = getLocalStorage();
    if (storage) {
      navigate("/listing");
    }
  };
  // Showing error message if error is true
  const errorMessage = () => {
    return (
      <div
        className="error text-center mt-4"
        style={{
          display: error ? "" : "none",
        }}
      >
        <h6 className="text-red">Invalid username/password!</h6>
      </div>
    );
  };
  return (
    <>
      <main>
        <section className="relative w-full h-full py-40 min-h-screen">
          <div className="absolute top-0 w-full h-full bg-lblue bg-no-repeat bg-full"></div>
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-5/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-white border-0">
                  <div className="flex-auto px-4 lg:px-10 py-10">
                    <h1>Login here</h1>
                    <p className="m-0 pt-0 mb-4 text-silver text-lg">
                      To access all the data points
                    </p>
                    <form onSubmit={handleSubmit}>
                      <div className="relative w-full mb-3 login-form">
                        <label className="block mb-2" htmlFor="grid-password">
                          Email ID
                        </label>

                        <input
                          type="email"
                          placeholder="Enter your email"
                          name="email"
                          value={email}
                          onChange={(e) => setemail(e.target.value)}
                        />
                      </div>

                      <div className="relative w-full mb-3 login-form">
                        <label className="block  mb-2" htmlFor="grid-password">
                          Password
                        </label>

                        <input
                          type="password"
                          placeholder="**************"
                          name="Password"
                          onChange={(e) => setpassword(e.target.value)}
                        />
                      </div>

                      <div className="flex items-center justify-between  mt-6">
                        <div></div>

                        <div className="text-center">
                          <button className="def-btn" type="submit">
                            Login now
                          </button>
                        </div>
                      </div>
                    </form>
                    <div className="messages">{errorMessage()}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Login;
