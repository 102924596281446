import { Link } from "react-router-dom";

function NotFoundPage() {
  return (
    <section className="reg-wrap mt-30 pt-20 text-center">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-12 mx-auto">
            <div className="notfound-block">
              <h1>404 Error</h1>
              <p>Seems like you're at wrong path!</p>
              <Link className="def-btn mt-4" to="/">
                Back to home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NotFoundPage;
