import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import delImg from "../images/delete.svg";
import editImg from "../images/edit.svg";
import Sidemenu from "./menu/sidemenu";
import { getToken } from "../utils/helper";
import axios from "axios";
import moment from "moment";
import { fetchUsers, updateApproved } from "../modules/listing/actions";
import { showSuccessMsg } from "../utils/notification";
import { updateUser } from "../modules/listing/actions";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const API_ENDPOINT = process.env.REACT_APP_BASE_URL;

const Userdetails = () => {
  const [coinList, setCoinList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [showhold, setShowhold] = useState(false);

  const [modEvthold, setModEvthold] = useState(null);

  const [userId, setUserId] = useState(null);
  const handleClosehold = () => setShowhold(false);

  useEffect(() => {
    getAllCoins();
  }, []);

  const getAllCoins = () => {
    setIsLoading(true);
    fetchUsers().then(
      (res) => {
        let list = res.data;
        setCoinList(list);
        setIsLoading(false);
      },
      (err) => {
        setIsLoading(false);
      }
    );
  };

  const holdChange = (event, id) => {
    setShowhold(true);
    setModEvthold(event);
    setUserId(id);
  };

  const holdStatus = () => {
    var urlencoded = new URLSearchParams();
    urlencoded.append("data[status]", modEvthold);
    urlencoded.append("id", userId);
    updateUser(urlencoded, userId).then(
      (res) => {
        setUserId(null);
        setModEvthold(null);
        setShowhold(false);
        // console.log(res);
        showSuccessMsg("Updated successfully!");
      },
      (err) => {
        setUserId(null);
        setModEvthold(null);
        setShowhold(false);
        showSuccessMsg("Something went wrong!");
        // console.log(err);
      }
    );
  };

  return (
    <>
      <Sidemenu />
      {/*  this is for 2nd popup for hold button */}
      <Modal show={showhold} onHide={handleClosehold}>
        <Modal.Header closeButton>
          <Modal.Title>
            {modEvthold === "0"
              ? "Disable"
              : modEvthold === "1"
              ? "Activate"
              : null}{" "}
            Confirmation
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Do you really want to
          {modEvthold === "0"
            ? " disable"
            : modEvthold === "1"
            ? " activate"
            : null}{" "}
          this user?
          {/* Do you really want to buy {shareAmt} shares of {data.title}? */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClosehold}>
            No
          </Button>
          <Button variant="primary" onClick={holdStatus}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="relative md:ml-64 bg-blueGray-100 mt-30 px-4 ">
        <nav className="w-full bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center">
          <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap">
            <h3>All User Details</h3>
          </div>
        </nav>

        <div className="relative bg-lightBlue-600 pb-32 dash-table mt-10">
          <div className="block w-full overflow-x-auto">
            {isLoading && <h3 className="text-center">Loading Data...</h3>}
            {!isLoading && (
              <table className="items-center w-full bg-transparent border-collapse">
                <thead>
                  <tr>
                    <th>User Name</th>

                    <th>Status</th>
                    {/* <th>Delete</th>
                    <th>Edit</th> */}
                    {/* <th>Select</th> */}
                  </tr>
                </thead>
                <tbody>
                  {coinList.map((data, index) => (
                    <tr key={index}>
                      <td>{data.user_name}</td>

                      <td>
                        <span className="price-sp">
                          <select
                            name="status"
                            id={index}
                            onChange={(e) =>
                              holdChange(e.target.value, data.id)
                            }
                            className={`form-control d-inline-flex price-select border ${
                              data.status === 0
                                ? "border-danger text-danger"
                                : "border-success text-success"
                            }`}
                          >
                            <option
                              data-price="0"
                              value="0"
                              selected={data.status === 0 ? "selected" : ""}
                            >
                              Inactive
                            </option>
                            <option
                              value="1"
                              selected={data.status === 1 ? "selected" : ""}
                            >
                              Active
                            </option>
                          </select>
                        </span>
                      </td>
                      {/* <td>
                        <img src={delImg} />
                      </td>
                      <td>
                        <img src={editImg} />
                      </td> */}
                      {/* <td>
                        <input type="checkbox" />{" "}
                      </td> */}
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Userdetails;
