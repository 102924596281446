import { showErrorMsg } from "./notification";

const storageKeyName = "blits-estate-admin";

export const httpHandleError = (error) => {
  /* error = {error,config, code, request, response } */
  try {
    var xhr = error.request;
    if (!xhr.response) {
      return Promise.reject({});
    }

    var err = xhr.response;
    if (xhr && xhr.status && err) {
      switch (xhr.status) {
        case 400:
        case 401:
          logout();
          showErrorMsg(err.error);
          break;
        case 404:
          showErrorMsg(err.error);
          break;
        case 406:
          showErrorMsg(err.error);
          break;
        case 422:
          if (err.errors) {
            showErrorMsg(err.errors[Object.keys(err.errors)[0][0]]);
            break;
          }
          showErrorMsg(err.error);
          break;

        default:
          showErrorMsg("An internal error occur");
      }
    } else {
      showErrorMsg("An internal error occur");
    }
    return Promise.reject({});
  } catch (e) {
    return Promise.reject({});
  }
};

//   get local token
export const getToken = () => {
  const storage = getLocalStorage();
  if (storage) {
    const { token } = JSON.parse(storage);
    return token;
  }
  return null;
};

export const getLocalStorage = () => {
  return localStorage.getItem(storageKeyName);
};

export const setLocalStorage = (data) => {
  if (!data) return;
  localStorage.setItem(storageKeyName, JSON.stringify(data));
};

export const deleteLocalStorage = () => {
  return localStorage.removeItem(storageKeyName);
};

export const logout = () => {
  deleteLocalStorage();
};
