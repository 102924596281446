/**
 * Import action creator constants & dependencies
 */

import axios from "axios";
// import qs from 'qs/lib/index';
import { getToken } from "../../utils/helper";
const cancelApiRequests = [];
const API_ENDPOINT = process.env.REACT_APP_BASE_URL;

/**
 *  User Details
 */
export async function getUserProfile(params) {
  const token = getToken();
  try {
    const response = await axios({
      method: "get",
      url: `${API_ENDPOINT}/user`,
      data: params,
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.error;
  }
}
