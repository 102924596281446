import { getToken } from "../../utils/helper";
import axios from "axios";

const API_ENDPOINT = process.env.REACT_APP_BASE_URL;

// Get Blogs
export async function getBlogs() {
  try {
    const response = await axios({
      method: "get",
      url: `${API_ENDPOINT}/blogs`,
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

// Add Blog
export async function addBlog(params) {
  try {
    const response = await axios({
      method: "post",
      url: `${API_ENDPOINT}/blogs/add`,
      data: params,
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}

// Delete Blog
export async function deleteSingleBlog(id) {
  try {
    const response = await axios({
      method: "delete",
      url: `${API_ENDPOINT}/blogs/delete/${id}`,
    });
    if (response.data) {
      return response.data;
    }
    throw response;
  } catch (error) {
    throw error.response.data;
  }
}
