import React from "react";
import { NavLink } from "react-router-dom";

const sidemenu = () => {
  return (
    <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-lblue-100 flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4">
      <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center pt-16 w-full mx-auto dash-left">
        <h2>Dashboard</h2>
        <ul className="sidebar-menu">
          {/* <li>
            <a href="">All investers</a>
          </li> */}
          <li className="active">
            <NavLink to={`/listing`} className="buy-btn">
              All Properties
            </NavLink>
          </li>
          <li className="active">
            <NavLink to={`/coindetails`} className="buy-btn">
              Coin Transactions
            </NavLink>
          </li>
          <li className="active">
            <NavLink to={`/userinvestments`} className="buy-btn">
              All User Investments
            </NavLink>
          </li>
          <li className="active">
            <NavLink to={`/userdetails`} className="buy-btn">
              User Details
            </NavLink>
          </li>
          <li className="active">
            <NavLink to={`/agent-inquiry`} className="buy-btn">
              Agent Inquiry
            </NavLink>
          </li>
          <li className="active">
            <NavLink to={`/seller-inquiry`} className="buy-btn">
              Seller Inquiry
            </NavLink>
          </li>
          <li className="active">
            <NavLink to={`/buyer-inquiry`} className="buy-btn">
              Buyer Inquiry
            </NavLink>
          </li>
          <li className="active">
            <NavLink to={`/blogs`} className="buy-btn">
              Blogs
            </NavLink>
          </li>
          {/*  <li>
            <NavLink to={`/dashboard`} className="buy-btn">
              Dashboard
            </NavLink>
          </li>
          <li>
            <a href="">Blits transactions</a>
          </li>
          <li>
            <a href="">All property listings</a>
          </li>
          <li>
            <a href="">All users</a>
          </li>*/}
        </ul>
      </div>
    </nav>
  );
};

export default sidemenu;
